.potree_container {
  position: relative !important;
  height: calc(100vh - 80px) !important;
}

header {
  height: 80px;
  background: rgb(221, 221, 221);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 40px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  position: relative;
  z-index: 401;
}

header h1 {
  font-family: sans-serif;
}

nav {
  /* width: 50%; */
  padding: 0 40px;
}

nav ul {
  list-style-type: none;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

nav ul li {
  font-size: 1.5rem;
  font-family: sans-serif;
}

.popup-header {
  font-size: 1.4rem;
  color: black;
  display: block;
  padding-bottom: 20px;
}

.popup-description {
  font-size: 1.2 rem;
  color: #666;
  padding-bottom: 50px;
}

.popup-button {
  font-size: 1.3rem;
  color: white;
  background-color: black;
  outline: none;
  border: none;
  width: 100%;
  padding: 20px 30px;
  border-radius: 9px;
  cursor: pointer;
}

.leaflet-popup {
  transform: initial !important;
  left: 50px !important;
  top: 200px !important;
}

.leaflet-popup-content {
  padding: 40px;
}

.leaflet-popup-tip-container {
  display: none;
}

#potree_sidebar_container {
  position: static;
}

#tools {
  position: absolute;
  bottom: 80px;
  left: 50%;
  display: flex;
  z-index: 200;
  transform: translateX(-50%);
  background-color: #333;
  border-radius: 20px;
  padding: 10px 20px;
}

#tools img {
  width: 50px !important;
  height: 50px !important;
}
